import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { Column, Hero, Container, Section, Content } from "rbx";
import { FacebookProvider, Page } from "react-facebook";
import { SizeMe } from "react-sizeme";
import PostSummary from "../components/PostSummary";

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <Layout>
        <Container>
          <Hero color="primary" size="large">
            <Hero.Body
              style={{
                backgroundImage: `url("/img/tennisplatz.jpg")`,
                backgroundPositionX: "center",
                backgroundPositionY: "center",
                backgroundSize: "cover"
              }}
            />
          </Hero>
        </Container>
        <Section>
          <Container>
            <Column.Group>
              <Column size={"half"}>
                <Content>
                  <h3 className="has-text-weight-bold is-size-4">
                    Neuigkeiten & Infos
                  </h3>
                </Content>
                {posts.map(({ node: post }) => (
                  <PostSummary post={post} />
                ))}
                <Link to="/neuigkeiten">Alle Neuigkeiten & Infos ...</Link>
              </Column>
              <Column size={"half"}>
                <Content>
                  <FacebookProvider appId="287622974751106">
                    <h3 className="has-text-weight-bold is-size-4">Facebook</h3>
                    <SizeMe>
                      {({ size }) => (
                        <Page
                          width={size.width}
                          smallHeader
                          showFacepile={false}
                          href="https://www.facebook.com/TennisvereinGrossweikersdorf/"
                          tabs="timeline"
                        />
                      )}
                    </SizeMe>
                  </FacebookProvider>
                </Content>
              </Column>
            </Column.Group>
          </Container>
        </Section>
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            description
            title
            termin
            templateKey
            date(formatString: "DD.MM.YYYY")
          }
        }
      }
    }
  }
`;
