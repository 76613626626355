import React from "react";
import { Link } from "gatsby";
import { Content, Tag } from "rbx";

const PostSummary = ({ post }) => (
  <Content
    style={{ border: "1px solid #333", padding: "2em 4em" }}
    key={post.id}
  >
    <p>
      <Tag className="is-pulled-right" color="primary">
        {post.frontmatter.date}
      </Tag>
      <Link className="has-text-primary" to={post.fields.slug}>
        {post.frontmatter.title}
      </Link>
    </p>
    <p>
      {post.frontmatter.termin || post.frontmatter.description}
      <br />
      <br />
      <Link className="button is-small" to={post.fields.slug}>
        Mehr Info →
      </Link>
    </p>
  </Content>
);

export default PostSummary;
